@use '../node_modules/@angular/material/index' as mat;

@import 'variables';
@import 'theme';
@import "styles/theme-initializer";
@import 'mixins';
@import "styles/basic-styles";
@import "styles/form-styles";
@import "styles/calendar-styles";
@import "styles/list-styles";
@import "styles/scrollbar-styles";
@import "styles/snackbar-styles";
@import "styles/dropzone-styles";
@import "styles/dialog-styles";
@import "styles/drag-drop-styles";
@import "styles/expansion-panel";
@import "styles/campaign-waterfall-styles";
@import "styles/product-list-styles";
@import "styles/dasboard-styles";

.content {
  height: calc(100% - 20px);
  box-sizing: border-box;
}

// Translation file
.translate-wrapper--strong {
  strong {
    font-weight: 600;
  }
}

.close-icon-big {
  font-size: 21px;
  line-height: 19px;
}

//Product select
.products-select-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.products-select, .form-control.select.products-select {
  flex: 1 1 120px;
  max-width: 250px;
  min-width: 120px;
}


.dropdown-cdk-panel {
}

.dashboard-action-buttons {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}

.content-scrollable {
  flex-grow: 1;
  min-height: 200px;
  overflow-y: auto;
}

.dashboard-tab-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 40px); // tab-content padding-bottom + padding-top
}

.form-controls-group--row-p-10 {
  .form-controls-row + .form-controls-row {
    padding-top: 10px;
  }
}

.dashboard-details-avatar {
  height: 55px;
  width: 55px;

  @include devices(laptop) {
    height: 34px;
    width: 34px;
  }
}


.icon-medium-scalable {
  height: 24px;

  @include devices(laptop) {
    height: 22px;
    line-height: 22px;
  }

  @include devices(desktop-xl) {
    height: 32px;
    line-height: 32px;
    font-size: 32px;
  }
}



.icon-x-clear {
  font-size: 16px;
  height: 15px;
  @include devices(laptop) {
    font-size: 16px;
    height: 16px;
  }

  @include devices(desktop-xl) {
    font-size: 18px;
    height: initial;
  }
}

@mixin tooltip-error {
  color: $status-error;
  border-color: $status-error!important;

  &.tooltip-top {
    &:before {
      border-top-color: $status-error!important;
    }
  }

  &.tooltip-right {
    &:before {
      border-right-color: $status-error!important;
    }
  }

  &.tooltip-left {
    &:before {
      border-left-color: $status-error!important;
    }
  }
}

@mixin tooltip-color($color) {
  color: $color;
  border-color: $color!important;

  &.tooltip-top {
    &:before {
      border-top-color: $color!important;
    }
  }

  &.tooltip-right {
    &:before {
      border-right-color: $color!important;
    }
  }

  &.tooltip-left {
    &:before {
      border-left-color: $color!important;
    }
  }
}

.tooltip-error {
 @include tooltip-error;
}

.tooltip-warning-secondary {
  @include tooltip-color($status-warning-secondary);
}

.tooltip-error-small {
  @include tooltip-error;
  max-width: 200px;
  white-space: normal;
}

.tooltip-primary {
  color: $primary-color;
  border-color: $primary-color!important;

  &.tooltip-top {
    &:before {
      border-top-color: $primary-color!important;
    }
  }

  &.tooltip-right {
    &:before {
      border-right-color: $primary-color!important;
    }
  }

  &.tooltip-left {
    &:before {
      border-left-color: $primary-color!important;
    }
  }
}

.tooltip-narrow {
  .tooltip-text {
    padding: 10px!important;
  }
  &:before {
    display: none!important;
  }
  &:after {
    display: none!important;
  }
}

.ad-unit-icon {
  height: 24px!important;
  font-size: 24px!important;

  @include devices(laptop) {
    height: 22px!important;
    line-height: 22px!important;
    font-size: 22px!important;
  }

  @include devices(desktop-xl) {
    height: 32px!important;
    line-height: 32px!important;
    font-size: 32px!important;
  }
}

a {
  all: unset;
}

.tab.active-tab.tab-error {
  background-color: $status-error!important;
}

.tab.active-tab.tab-warning {
  background-color: $status-warning-secondary!important;
}

.select-form-button.mat-mdc-outlined-button.select-button:not(:disabled).asset-product-select-btn {
  border-color: $dark-grey;
  background: transparent;
  .mdc-button__label span, .mdc-button__label fa-icon{
    color: $dark-grey;
  }
}

.select-form-button.mat-mdc-outlined-button.select-button:disabled.asset-product-select-btn {
  background: transparent;
}


.table-sticky-header {
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

.display-none-sm {
  @include devices(laptop-small) {
    display: none;
  }
}

.display-none-xs {
  @include devices(tablet) {
    display: none;
  }
}

.cell-text-wrapped {
  .cell-with-ellipsis {
    white-space: normal!important;
  }
}

.tooltip-product-url {
  &:before,
  &:after {
    content: none !important;
    display: none !important;
  }
  margin-left: 20px!important;
}
